import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Link,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";

const Shop = () => {
  const sectionColor = useColorModeValue("gray.800", "whiteAlpha.900");

  // Define an array of objects with book data
  const books = [
    {
      title: " The Gates of Hell - Book 0.5",

      link: "https://mybook.to/tgoh",
      imageUrl: "/book05cover.png",
    },
    {
      title: "The River of Fire - Book 1",

      link: "https://mybook.to/trof",
      imageUrl: "/book1cover.png",
    },
    // Add more books as needed
  ];

  return (
    <Box py={10} bg="transparent">
      <Container
        maxW="container.xl"
        display="flex"
        flexDir="column"
        alignItems="center"
        justifyContent="center"
      >
        <Heading as="h1" size="xl" mb={6} color="white">
          Shop
        </Heading>
        <SimpleGrid
          columns={{ base: 1, md: 3 }}
          spacing={10}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
        >
          {/* Render each book as a shop item */}
          {books.map((book, index) => (
            <ShopItem
              key={index}
              title={book.title}
              description={book.description}
              link={book.link}
              imageUrl={book.imageUrl}
            />
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
};

const ShopItem = ({ title, link, imageUrl }) => {
  return (
    <Box
      bg="rgba(255, 255, 255, 0.6)"
      p={6}
      borderRadius="lg"
      shadow="md"
      position="relative"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      maxWidth="500px"
    >
      <Box
        bg={`url(${imageUrl})`}
        bgSize="cover"
        bgPosition="center"
        bgRepeat="no-repeat"
        w="200px"
        h="300px"
        borderRadius="md"
        boxShadow="md"
        mb={4}
      />

      <Heading as="h2" size="lg" mb={4} color="black">
        {title}
      </Heading>
      <Link href={link} isExternal color="black" target="_blank">
        <Text fontSize="lg" fontWeight="bold">
          Shop Now
        </Text>
      </Link>
    </Box>
  );
};

export default Shop;
