// src/components/reviews.js
import React from "react";
import { Box, Heading, SimpleGrid, Image } from "@chakra-ui/react";

const ArtSection = () => {
  return (
    <Box
      p={4}
      display="flex"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
    >
      <Heading as="h1" size="xl" mb={10}>
        The Rivers of Hell Art
      </Heading>
      <ArtGrid art={art} />
    </Box>
  );
};

const ArtGrid = ({ art }) => {
  return (
    <SimpleGrid
      columns={{ base: 1, md: 3 }}
      spacing={10}
      mb={10}
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
    >
      {art.map((art, index) => (
        <Art key={index} art={art} />
      ))}
    </SimpleGrid>
  );
};

const Art = ({ art }) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Image
        src={art.image}
        alt={art.name}
        borderRadius="md"
        width="300px" // Set the desired width
        height="400px" // Set the desired height
        objectFit="cover" // Ensures the images cover the set dimensions
      />
    </Box>
  );
};
const art = [
  {
    name: "Art 1",
    image: "/Ash-2.jpg",
  },
  {
    name: "Art 2",
    image: "/ash&lana-book.jpg",
  },
  {
    name: "Art 3",
    image: "/image0.jpeg",
  },
  {
    name: "Art 4",
    image: "/image1.jpeg",
  },
  {
    name: "Art 5",
    image: "/image2.jpeg",
  },
  {
    name: "Art 6",
    image: "/image3.jpeg",
  },
  {
    name: "Art 7",
    image: "/image4.jpeg",
  },
  {
    name: "Art 8",
    image: "/image5.jpeg",
  },
  {
    name: "Art 9",
    image: "/image6.jpeg",
  },
  {
    name: "Art 10",
    image: "/Puck.jpg",
  },
  {
    name: "Art 11",
    image: "/IMG_7850.jpg",
  },
  {
    name: "Art 12",
    image: "/IMG_7870.jpg",
  },
  {
    name: "Art 13",
    image: "/IMG_7904.jpg",
  },
  {
    name: "Art 14",
    image: "/image01.jpeg",
  },
  {
    name: "Art 15",
    image: "/IMG_8441.jpeg",
  },
  {
    name: "Art 16",
    image: "/IMG_8450.jpeg",
  },
  {
    name: "Art 17",
    image: "/10.jpg",
  },
  {
    name: "Art 18",
    image: "/11.jpg",
  },
  {
    name: "Art 19",
    image: "/12.png",
  },
  {
    name: "Art 20",
    image: "/222.jpeg",
  },
  {
    name: "Art 21",
    image: "/111.jpg",
  },
];

export default ArtSection;
