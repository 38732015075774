import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Flex,
  Image,
  Heading,
  Link,
  useColorModeValue,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

const Navbar = () => {
  const bgColor = useColorModeValue("transparent", "transparent");

  return (
    <Box bg={bgColor} p={4} shadow="md">
      <Flex
        maxW="container.xl"
        mx="auto"
        align="center"
        justifyContent="space-between"
        direction={{ base: "column", md: "row" }} // Stack for smaller screens
      >
        <Heading
          as="h2"
          size="lg"
          color="white"
          display={{ base: "none", md: "block" }}
        >
          <Link
            as={RouterLink}
            to="/"
            _hover={{
              textDecoration: "none",
              fontFamily: "Megrim, sans-serif",
            }}
          >
            <Image
              src="/logo.png"
              alt="Liana Valerian Logo"
              height="100px"
              filter="invert(1)"
            />
          </Link>
        </Heading>
        <Wrap
          spacing="12px"
          justify="center"
          direction={{ base: "row", md: "row" }} // Control layout for different screens
          wrap={{ base: "wrap", md: "nowrap" }} // Enable wrapping on small screens
        >
          <WrapItem>
            <NavLink to="/about">About</NavLink>
          </WrapItem>
          <WrapItem>
            <NavLink to="/books">Books</NavLink>
          </WrapItem>
          <WrapItem>
            <NavLink to="/reviews">Reviews</NavLink>
          </WrapItem>
          <WrapItem>
            <NavLink to="/art">Art</NavLink>
          </WrapItem>
          <WrapItem>
            <NavLink to="/readings">Readings</NavLink>
          </WrapItem>
          <WrapItem>
            <NavLink to="/contact">Contact</NavLink>
          </WrapItem>
          <WrapItem>
            <NavLink to="/shop">Shop</NavLink>
          </WrapItem>
          <WrapItem>
            <NavLink to="/newsletter">Newsletter</NavLink>
          </WrapItem>
        </Wrap>
      </Flex>
    </Box>
  );
};

const NavLink = ({ to, children }) => {
  const color = useColorModeValue("whiteAlpha.900");
  const hoverColor = useColorModeValue("#d791a6");

  return (
    <Link
      as={RouterLink}
      to={to}
      p={2}
      mx={2}
      fontSize="md"
      fontWeight="bold"
      color={color}
      _hover={{ textDecoration: "none", color: hoverColor }}
    >
      {children}
    </Link>
  );
};

export default Navbar;
